import React from 'react';
import { createUseStyles } from 'react-jss';

import { colors } from '@/theme';

const { grey, white } = colors;

const useStyles = createUseStyles({
  container: {
    flex: [1, 1, 'auto'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '40%',
    zIndex: 100,
    color: white,
    '@media (max-width: 800px)': {
      marginLeft: 0,
      color: grey[70],
    },
  },
});

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h1>Nicht gefunden</h1>
      <p>Die gesuchte Seite existiert nicht.</p>
    </div>
  );
};

export default NotFoundPage;
